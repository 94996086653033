.panel-header {
  background-color: white;
  width: 120px;
  color: #303c4a;
  font-size: 20px;
  border-start-end-radius: 10px;
  border-top-left-radius: 10px;
  margin-left: 10px;
  cursor: pointer;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.panel-body-container {
  box-shadow: 0px 15px 15px 0px rgb(0 0 0 / 52%);
  background-color: white;
  height: calc(100% - 30px);
  border-radius: 10px;
  padding: 30px 10px;
  position: relative;
}

.replays-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 5px;
  max-height: calc(100% - 180px);
  overflow: auto;
}

.replay-card {
  background-color: #eff4f7;
  width: calc(100% - 10px);
  margin: 5px;
  border-radius: 5px;
  height: 60px;
  color: gray;
  cursor: pointer;
  border: 2px transparent solid;
  position: relative;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 20%)
}

.replay-card-default {
  background-color: #008000a6;
  width: calc(90% - 20px);
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 5px;
  height: 50px;
  color: white;
  cursor: pointer;
  border: 2px transparent solid;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 10px 3px 10px rgb(0 0 0 / 0.2);
}

.replay-card-default:hover {
  border: 2px #00800078 solid;
}

.replay-card:hover {
  border: 2px #00800078 solid;
}

.inputs-container {
  height: 80%;
  overflow: auto;
  padding: 0 10px;
  position: relative;
}

.save-refresh-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 30px;
  height: 50px;
  width: calc(100% - 30px);
}

.replay-card-menu {
  position: absolute;
  display: inline-block;
  right: 10px;
  top: 10px;
}

.replay-card-sub-menu {
  display: none;
}

.replay-card-sub-menu-icon {
  font-size: 16px;
}

.replay-card-menu-icon {
  font-size: 16px;
}

.replay-card-sub-menu-icon:hover {
  color: blue;
}

.replay-card-menu:hover .replay-card-sub-menu {
  display: block;
}

.replay-card-download-icon {
  position: absolute;
  right: 50px;
  top: 12px;
  font-size: 16px;
}

.replay-card-download-icon:hover {
  color: blue;
}

.replay-card-automated-download-icon {
  position: absolute;
  right: 75px;
  top: 12px;
  font-size: 16px;
}

.replay-card-automated-download-icon:hover {
  color: blue;
}

.replay-card-clone-icon {
  position: absolute;
  right: 25px;
  top: 12px;
  font-size: 16px;
}

.replay-card-clone-icon:hover {
  color: blue;
}

.replay-card-delete-icon {
  position: absolute;
  right: 5px;
  top: 12px;
  font-size: 16px;
}

.replay-card-delete-icon:hover {
  color: red;
}

.preview-container {
  height: calc(100% - 100px);
}

.videos-page {
  width: 100%;
  height: 100vh;
}

.videos-page-container {
  height: calc(100vh - 60px);
  background-color: white;
  display: flex;
  overflow: auto;
  flex-direction: column;
}

.videos-page-manual-container {
  min-height: calc(100vh - 60px);
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.videos-tabs-container {
  width: calc(100vw - 100px);
  height: 80%;
}

.width-25 {
  width: 25% !important;
}

.replay-card-edit-icon:hover {
  color: blue;
}

.anticon-spin {
  animation: loadingCircle 10s infinite linear;
}

.panel-body-container .ant-slider-rail {
  background-color: gray;
}

.panel-body-container .ant-slider:hover {
  opacity: 0.8;
}

.hand-icon {
  width: 64px;
  height: 64px;
  margin: 4px;
  border-radius: 4px;
  overflow: hidden;
  border: 3px solid transparent;
  cursor: pointer;
  box-shadow: -1px 1px 10px rgb(0 0 0 / 20%);
}

.hand-icon-disabled {
  width: 64px;
  height: 64px;
  margin: 4px;
  border-radius: 4px;
  overflow: hidden;
  border: 3px solid transparent;
  cursor: pointer;
  box-shadow: -1px 1px 10px rgb(0 0 0 / 20%);
  filter: grayscale(1);
}

.hand-icon:hover {
  border: 3px solid orange !important;
}

.sidebar-videos {
  margin-top: 10px;
}

.sidebar-videos .ant-collapse {
  background-image: linear-gradient(90deg, #b9914a 2.06%, #e561c0 102.06%);
  font-size: 15px;
  margin-right: 15px;
  margin-left: 15px;
  border: none;
  font-family: productBold;
  border-radius: 10px !important;
}

.sidebar-videos .ant-collapse-header {
  color: white !important;
}

.sidebar-videos .ant-collapse-content-box {
  background-image: linear-gradient(90deg, #b9914a 2.06%, #e561c0 102.06%);
  padding: 0px;
  padding-bottom: 15px;
  border-radius: 10px;
}

.sidebar-videos .ant-collapse-item {
  background-image: linear-gradient(90deg, #b9914a 2.06%, #e561c0 102.06%);
  border-radius: 10px !important;
}

.sidebar-videos .ant-collapse-content {
  border: none;
  color: white;
  font-size: 13px;
  border-radius: 10px !important;
  background-image: linear-gradient(90deg, #b9914a 2.06%, #e561c0 102.06%);
}

.ant-popover {
  z-index: 9999;
}

.videos-page-manual-container .react-pdf__Document {
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
  width: 60vw;
  height: 33vw;
  overflow: hidden;
  position: relative;
}

.videos-page-manual-container .users-manual-page-container {
  position: absolute;
  bottom: 5%;
  left: 50%;
  background: white;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity ease-in-out 0.2s;
  box-shadow: 0 30px 40px 0 rgb(16 36 94 / 20%);
  border-radius: 4px;
  color: black;
}

.videos-page-manual-container .react-pdf__Document:hover .users-manual-page-container {
  opacity: 1;
}

.videos-page-manual-container .page-controls-button {
  width: 44px;
  height: 44px;
  background: white;
  border: 0;
  font: inherit;
  font-size: 0.8em;
  border-radius: 4px;
  cursor: pointer;
  font-size: 20px;
}

.videos-page-manual-container .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.refresh-button-active {
animation: pulse 1s infinite;
}
.replay-name-input:hover{
  border: 1px solid gray !important
}
.replay-name-input:focus {
  border: 1px solid gray !important
}

.video-replay-name-input {
  transition: border 0.15s ease;
}

.video-replay-name-input:hover{
  border-bottom: 1px solid gray !important
}
.video-replay-name-input:focus {
  border-bottom: 1px solid gray !important
}

.collapse-panel {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
}

.collapse-panel .preview-panel {
  max-width: 3vw;
  width: 2.5vw;
  min-width: 2.5vw;
  border-radius: 0 8px 8px 0;
  height: 100%;
  background-color: rgb(243 244 246);
  border: 1px solid rgb(107 114 128);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.preview-panel span {
  font-weight: 500;
  font-size: large;
  cursor: pointer;
}

.content-panel {
  width: 0%;
  background-color: rgb(243 244 246);
  transition: width 0.3s linear;
}

.version-panel {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 100%;
}

.versions-panel-list::-webkit-scrollbar {
  display: none;
}

.d-ltr {
  direction: ltr
}

.d-rtl {
  direction: rtl;
}

.preview-rtl {
  border-radius: 8px 0 0 8px !important;
}
.parameters-panel {
  position: absolute !important;
  bottom: 0;
}