.shadow {
    box-shadow: 0 20px 35px -16px rgba(112, 112, 112, 1);

}

.shadow2 {
    box-shadow: 0 1px 5px 0 rgba(112, 112, 112, 0.5);

}

input:focus, textarea:focus, select:focus {
    outline: none;
}

body {
    background: #303C4A;
    color: white;
    margin: 0;
    font-family: productRegular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

.ant-slider-step {
    background: darkgray !important
}

.btn {
    position: relative;
    border: none;
    height: 70px;
    padding: 10px;
    width: 220px;
    border-radius: 20px;
    align-items: center;
    justify-content: flex-start;
    color: black;
    background: white;
}

.btn .icon.right {
    left: inherit;
    right: 10px;
}

.btn .txt {
    width: 100%;
    display: block;
    text-align: center;
}

.input {
    height: 44px;
    padding: 8px;
    width: 220px;
    border-radius: 8px;
}

.input .anticon {
    left: 10px;
    color: grey;
    margin: 10px;
    margin-right: 20px;
}

.input .anticon.right {
    right: 10px;
}

.btn .icon {
    position: absolute;
    left: 10px;
    width: 28px;
    height: 28px;
    padding: 5px;
    border-radius: 10px;
    background: #F78645;
}

.btn-small {
    height: 50px;
    border-radius: 14px;
}

.btn-yellow {
    background: #EEB44F;
    color: white
}

.btn-grey {
    background: #e6ecf5;
}

.btn-yellow img {
    background: white;
}


.btn span {
    font-size: 18px;
}

.btn:hover {
    background: #f2f2f2;
}

.btn.btn-yellow:hover {
    background: #f5c063;
}

.bg-dark {
    background: #303C4A;
}

.bg-dark2 {
    background: #43484B;
}

.bg-white {
    background: white
}

.bg-white:hover {
    background: white
}

.bg-grey {
    background: #e6ecf5
}

.bg-yellow {
    background: #CFA14E;
}

.ant-switch-checked {
    background: #EEB44F
}

.bg-orange {
    background: #F78644;
}

.bg-orange2 {
    background: #FA9110;
}

.bg-orange3 {
    background: #FA6424;
}

.ant-popover-open {

}

.bg-yellow {
    background: #EEB44F
}


.txt-yellow {
    color: #EEB44F
}

.txt-white {
    color: white
}

.txt-dark {
    color: black
}

.txt-blue {
    color: #2B5ECA
}

.txt-grey {
    color: #AFB4BA
}

.txt-grey2 {
    color: #a1a1a1
}

.noscroll::-webkit-scrollbar {
    display: none;
}

.noscroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.smallscroll {
    overflow-x: hidden !important;
    -ms-overflow-style: inherit; /* IE and Edge */
    scrollbar-width: inherit; /* Firefox */
}

.smallscroll::-webkit-scrollbar {
    display: none;
}

.smallscroll::-webkit-scrollbar {
    display: inherit;
}


.smallscroll::-webkit-scrollbar {
    width: 6px;
}


.smallscrollX {
    overflow-y: hidden !important;
    -ms-overflow-style: inherit; /* IE and Edge */
    scrollbar-width: inherit; /* Firefox */
}

.smallscrollX::-webkit-scrollbar {
    display: none;
}

.smallscrollX::-webkit-scrollbar {
    display: inherit;
}


.smallscrollX::-webkit-scrollbar {
    height: 6px;
}


/* Track */
::-webkit-scrollbar-track {

    background: #00000010;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
    height: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

body::-webkit-scrollbar {
    display: none;
}


.smallscroll.white::-webkit-scrollbar-track {
    background-color: white;
}


body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}


code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.layout {

}

.header {
    display: flex;
    height: 70px;
    align-items: center;
    margin-bottom: 40px;
}

.header .content {
    display: flex;
    flex: 1;
    padding-left: 30px;
    padding-right: 30px;
    height: 60px;
    justify-content: space-between;
}

.logo {
    width: 240px;
    padding: 20px;
    height: 50px;
    display: flex;
    font-size: 26px;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
}

.header .welcome {
    flex: 1;
    font-size: 30px;
    font-weight: bold;
}

.header .welcome > span.txt-grey {
    display: block;
    font-size: 16px;
}

.logo img {
    display: block;
    height: 40px;
    width: 40px;
    margin-right: 10px;
}

.header .right {
    display: flex;
}

.cleanbtn > img {
    width: 28px;
}

.cleanbtn {
    width: 50px;
    padding: 5px;
    border: none;
    background: none;
    margin-right: 10px;
    height: 50px;
    border-radius: 10px;
}

.search .ant-input {
    padding-left: 20px !important;

}

.searchbtn {
    border-radius: 10px;
    padding: 5px;
    width: 50px;
    height: 50px;
    background: #3B4653;
    border: none;
    margin-right: 10px;
}

.searchbtn > img {
    width: 20px;
}

.profilebtn {
    border-radius: 10px;
    padding: 10px;
    height: 50px;
    background: #3B4653;
    border: none;
    min-width: 240px;
    display: flex;
    align-items: center;

}

.profilebtn img {
    width: 32px;
    border-radius: 16px;
    margin-right: 10px;
}

.profilebtn img.more {
    width: 20px;
    margin-right: 4px;
}


.profilebtn span {
    font-size: 20px;
    flex: 1;
}

.main {
    display: flex;
}

.title {
    font-weight: bold;
    font-size: 21px;
    margin: 2px
}

.card {
    border-radius: 36px;
    padding: 30px;
}

.full {
    width: 100%;
}

.sidemenu {
    height: 100vh;
    width: 240px
}

.float-right {
    float: right
}

.sidemenu .createPlayable {
    border: none;
    height: 54px;
    padding: 10px;
    width: 220px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.sidemenu .createPlayable img {
    width: 30px;
    height: 30px;
    margin-right: 5px;;
    padding: 8px;
    margin-right: 16px;
    border-radius: 10px;
    background: white;
}

.sidemenu .createPlayable span {
    font-size: 16px
}


.sidemenu ul {
    padding: 0;
}

.sidemenu ul li {
    list-style-type: none;
}

.sidemenu ul li button {
    border: none;
    height: 60px;
    padding: 10px;
    width: 230px;
    display: flex;
    align-items: center;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: none;
    font-size: 16px;
    color: white;
    font-weight: 600;
}

.sidemenu ul.small li button {
    font-size: 12px;
}

.sidemenu ul li button img {
    width: 30px;
    height: 30px;
    padding: 6px;
    margin-right: 10px;
    margin-left: 5px;
    position: relative;
}

.sidemenu ul li button.active {
    background: #e6ecf5;
    color: black

}

.sidemenu ul li button .item {
    display: none;
    z-index: 10;
}

.sidemenu ul li button .item .bg {
    display: none;
    z-index: 10;
}

.sidemenu ul li button.active .item {
    display: inherit;
    position: absolute;
    background: #e6ecf5;
}


.sidemenu ul li button.active .round {

    background: #303C4A;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-left: 210px;
}

.sidemenu ul li button.active .bg {
    height: 78px;
    width: 10px;
    margin-left: 220px;
    margin-top: -38px;
}

.sidemenu ul li button .round-c {
    position: absolute;
}

.sidemenu ul li button.active .round.top {
    margin-top: -50px;
}

.sidemenu ul li button.active .round.bottom {
    margin-top: 30px;
}

.sidemenu ul li button.active img {
    border-radius: 10px;
    background: white;
}

.main > .content {
    flex: 1;
    display: flex;
    border-radius: 36px;
    padding: 40px
}

button {
    outline: none;

}

.quickAccess {
    padding: 20px;
    width: 100%;
}

.quickAccess .games {
    display: flex;
    flex-wrap: wrap;
}

.quickAccess .games > div {
    margin-right: 16px;
    margin-bottom: 16px;
    width: 260px;
    height: 186px;
    padding: 14px;;
    margin-top: 50px;
    border-radius: 24px;
}

.quickAccess .games > div .name {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    padding: 10px;
    margin: 0;
    margin-top: 50px;
}

.quickAccess .games > div > img {
    position: absolute;
    width: 80px;
    height: 80px;
    transform: translateY(-50%);
    margin-left: 80px;
    border-radius: 20px;;
    box-shadow: 0 25px 35px -16px rgba(112, 112, 112, 1);

}

.quickAccess .games .btn {
    width: 100%
}

.overview-contents {
    display: flex;
    flex-wrap: wrap;
}

.overview-contents > div {
    max-width: 600px;
}

.overview-contents {
    display: flex;
    margin-top: 40px;
    flex-wrap: wrap;
}

.news {
    height: 500px;
}

.news > .card .title {
    margin-bottom: 20px;
}

.news > .card {
    display: flex;
    flex-direction: column;
    height: 400px;
    margin-right: 20px;
}

.news > .card > .content {
    flex: 1;
    font-size: 16px;
}

.news .pager {
    margin-top: 30px;
    height: 40px;
    display: flex;
    justify-content: space-between;
}


.card.versions {
    margin-left: 20px;
    height: 500px;
}


.mygames {
    padding: 20px;
    width: 100%;
}

.mygames .games {
    display: flex;
    flex-wrap: wrap;
}

.mygames .games > div {
    margin-right: 16px;
    margin-bottom: 16px;
    width: 340px;
    padding: 20px;
    height: 186px;
    margin-top: 50px;
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.mygames .games > div .hr {
    height: 1px;
    width: 100%;
    border-top: #a1a1a1 1px solid;
}

.mygames .games .game > div {
    display: flex;
}

.mygames .games .game .name {
    font-size: 22px;
    font-weight: 600;
    width: 100%;
    margin: 0;
}

.mygames .games .game .info {
    font-weight: 600;
    width: 100%;
    padding: 0;
    margin: 0;
}

.mygames .games .game .logo {
    padding: 0;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    margin-right: 10px;
    box-shadow: 0 25px 35px -16px rgba(112, 112, 112, 1);

}

.mygames .games .btn {
    width: 100%
}

.ripple-button {
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.ripple-button > .ripple {
    width: 20px;
    height: 20px;
    position: absolute;
    background: #fa845690;
    display: block;
    content: "";
    border-radius: 9999px;
    opacity: 1;
    /* animation: 1.2s ease 1 forwards ripple-effect; */
}

@keyframes ripple-effect {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(10);
        opacity: 0;
    }
}

.ripple-button > .content {
    position: relative;
    z-index: 2;
}

.ripple-button.rip {
    animation: 0.15s ease-in-out 1 forwards ripple-effect-rip;
}


@keyframes ripple-effect-rip {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.025);
    }
    100% {
        transform: scale(1);
    }
}

.sidebtn:hover > span {
    color: #EEB44Ff0;
}

.sidebtn:hover * {
    fill: #EEB44Ff0 !important;
}


.hovergrey:hover {
    background: #f5f8fb !important;
    /* fill: black !important */
}

.hovergrey3:hover {
    background: red !important;
    /* fill: black !important */
}

.hovergrey:hover * {
    /* fill: f5f8fb !important */
}

.hovergrey2:hover {
    box-shadow: 0 20px 35px -30px rgb(202, 198, 198);

    background: #dee1e4 !important;
    /* fill: black !important */
}

.hovergrey2:hover * {
    /* fill: f5f8fb !important */
}


.hoverorange:hover {
    box-shadow: 0 20px 35px -30px rgb(202, 198, 198);

    background: #fa9b60 !important;
    /* fill: black !important */
}

.ant-pagination {
    height: 50px;
}

.ant-pagination li {
    color: #c1c1c1 !important;
    border-radius: 100px !important;
    border: none !important
}

.ant-pagination li .ant-btn {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    font-family: productBold;
    height: 40px !important;
    min-width: 40px !important;
    color: #a1a1a1 !important;
    border-radius: 100px !important;
}

.ant-pagination li .ant-btn.ant-btn-primary {
    background: #f78743;
    color: white !important;
}

.ant-tabs-nav-list {
    width: 100%;
}

.ant-tabs-tab {
    flex: 1 !important
}

.ant-tabs-tab-btn {
    width: 100% !important;
}

.curved-modal {

}

.curved-modal > .ant-modal-content {
    border-radius: 8px;
}

.pf-input {
    border-radius: 8px;
    height: 36px;
    font-size: 14px;
    padding-left: 8px;
    color: black;
}

.ant-table-body::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

.nowrap {
    flex-wrap: nowrap;
}

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .font-thick {
        font-family: productBold;
    }
}

@layer components {
    .site-collapse-custom-collapse {
        @apply bg-transparent flex flex-col gap-2 select-none;
    }

    .site-collapse-custom-panel {
        @apply font-thick flex flex-col gap-2 rounded-lg;
        background: #EFF0F4;
    }
}